import React from "react"
import FormPage from "../../components/FormPage"
import './form-styles.css';

interface Props {

}

const ConciergeApplication = ({ }: Props) => {
	return <FormPage title="Concierge Application">
		<form className="form-labels-on-top" action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdeyOTeXGtcRxJTL02aR7E8vAWqeioyMOg7Pq4_zuhyh9q6xQ/formResponse?embedded=true" target="_blank" method="POST" id="mG61Hd">
			<div className="form-title-row">
				<h1>Concierge Service Application</h1>
			</div>
			<div style={{ textAlign: 'left' }}>
				<label className="required">* Required</label>
			</div>
			<div className="form-row">
				<label>
					<span>Full Name <span className="required">*</span></span>
					<input type="text" defaultValue="" autoComplete="off" tabIndex={0} aria-label="Full Name" name="entry.1593786954" required dir="auto" data-initial-dir="auto" data-initial-value="" />
				</label>
			</div>
			<div className="form-row">
				<label>
					<span>Email Address <span className="required">*</span></span>
					<input type="text" defaultValue="" autoComplete="off" tabIndex={0} aria-label="Email Address" name="entry.1644887552" required dir="auto" data-initial-dir="auto" data-initial-value="" />
				</label>
			</div>
			<div className="form-row">
				<label>
					<span>Budget for your project <span className="required">*</span></span>
					<span className="description">This can be a number or a range.</span>
					<input type="text" defaultValue="" autoComplete="off" tabIndex={0} aria-label="Budget for your project" name="entry.846502109" required dir="auto" data-initial-dir="auto" data-initial-value="" />
				</label>
			</div>
			<div className="form-row">
				<label>
					<span>Project Information <span className="required">*</span></span>
					<span className="description">Give me a brief description of the type of project you want to build.</span>
					<textarea data-rows="1" defaultValue="" tabIndex={0} aria-label="Project Information" required name="entry.729424211" dir="auto" data-initial-dir="auto" data-initial-value="" aria-describedby="i.desc.680410996 i.err.680410996" />
				</label>
			</div>
			<div className="form-row">
				<label>
					<span>Is there anything else I should know</span>
					<textarea data-rows="1" defaultValue="" tabIndex={0} aria-label="Is there anything else I should know?" name="entry.944028685" dir="auto" data-initial-dir="auto" data-initial-value="" aria-describedby="i.desc.532063638 i.err.532063638" />
				</label>
			</div>
			<button type="submit">Apply</button>
		</form>
	</FormPage>
}

export default ConciergeApplication
